import React from "react"
import Backsplash from "../components/Backsplash"
import Categories from "../components/Categories"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <Categories />
      <Backsplash />
    </Layout>
  )
}
